import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://1157e179c0a1eabaf884f06244d6c9a9@o4507542169452544.ingest.de.sentry.io/4507542175744080",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});